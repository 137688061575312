/**
 * Конфиг генерим PHP JSON через контроллер, передаем через <div data-yandex-map="URL">
 * У div карты должен стоять CSS height больше ничего не нужно по умолчанию
 * в <HEAD>:
 * <script src="https://api-maps.yandex.ru/2.1/?lang=ru_RU" type="text/javascript"></script>
 *
 * пример controller json
 *
 * public function yandex_map_config_json(){
 *         $this->jsonResponse(
 *             [
 *                 'config' => [
 *                     'center' => [55.752411, 37.609407],
 *                     'zoom' => 10,
 *                     'controls' => ['fullscreenControl','zoomControl'],
 *                 ],
 *                 'second_config' => [
 *                     'searchControlProvider' => 'yandex#search'
 *                 ],
 *                 'place_marks' => [
 *                     [
 *                         'geo' => [55.684758, 37.738521],
 *                         'config'=>[
 *                             'balloonContent' => 'цвет <strong>воды пляжа бонди</strong>',
 *                         ],
 *                         'second_config' => [
 *                             'iconColor' => '#0095b6',
 *                         ]
 *                     ]
 *                 ],
 *             ]
 *         );
 *     }
 */
window.addEventListener('DOMContentLoaded', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init)
    }
})

/**
 * Функция для получения JSON конфига карт по URL
 * Конфиг генерим PHP JSON через контроллер, передаем через data-yandex-map="URL"
 * @param url
 * @returns {Promise<any>}
 */
async function get_map_config(url){
    return  await (await fetch(url)).json()
}

/**
 * Яндекс карты инициализация
 */
function init() {
    /**
     * Ищем все карты на странице
     * @type {NodeListOf<Element>}
     */
    const yandex_map_selectors = document.querySelectorAll('[data-yandex-map]')
    yandex_map_selectors.forEach(selector => {
        if(selector) start(selector)
    })

    /**
     * Для конкретного селектора карт применяем настройки
     * @param selector
     * @returns {boolean}
     */
    function start(selector) {

        //Берем путь до конфига с dataset
        const config_url = selector.dataset.yandexMap;
        if(!config_url){
            return false
        }

        //когда подгрузили конфиг
        get_map_config(config_url).then(config => {

            //устанавливаем настройки с конфига
            const myMap = new ymaps.Map(selector, config.config, config.second_config)

            //отключаем зум при скроле по карте
            myMap.behaviors.disable('scrollZoom')


            //устанавливаем метки на карту
            config.place_marks.forEach(place_mark => {
                myMap.geoObjects.add(new ymaps.Placemark(place_mark.geo, place_mark.config, place_mark.second_config))
            })

            //если метка одна, то зум и центр карты по конфигу, если нет, то все метки захватываем разом
            if(config.place_marks.length > 1){
                myMap.setBounds(myMap.geoObjects.getBounds())
            }
        })
    }
}


