import Polipop from 'polipop';
import LiveEvent from "../../components/live/live";

// https://github.com/minitek/polipop
window.polipop = new Polipop('mypolipop', {
    layout: 'popups',
    insert: 'before',
    pool: 0,
    sticky: false,
    position: 'center',
    effect: 'fade',
    closer: false,
    life: 4000,
});

new LiveEvent('click', '[data-polipop]', function polipopLink(e) {
    e.preventDefault();

    polipop.add({
        content: this.dataset.polipop,
        type: 'info',
    });

});