import LiveEvent from "../../components/live/live";

new LiveEvent('click', '[data-panel]', function panel(e) {
    e.preventDefault();
    let index = this.dataset.panel;

    const panels = document.querySelectorAll('.panel .panel__content')
    panels.forEach(panel => {
        panel.classList.remove('panel_active')
    })


    const active_panel = document.querySelectorAll('[data-panel-name="'+index+'"]')
    active_panel.forEach(panel => {
       panel.classList.add('panel_active')
    })

    const panel_buttons = document.querySelectorAll('[data-panel]')
    panel_buttons.forEach(panel => {
        panel.classList.remove('b-icon-button_selected')
    })

    this.classList.add('b-icon-button_selected')

});
