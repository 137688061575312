require('../fonts/PTRootUI/style.css')
require('../scss/app.scss')

window.noZensmooth = true
require('./breakpoints')
require('./common/goal')
require('./common/js_validation') // Валидация форм
require('./common/links')
require('./common/main')
require('./common/pagination')
require('./common/respond')
require('./common/lazyloadImages') // Lazyload для картинок
require('./common/gridGap') // grid gap
require('./common/emit')
require('./common/multpleEventListener')
require('./common/_intl-tel-input')
require('./common/select2.full')
require('./common/self_ajax')
require('./common/panel')
require('./common/cookie_banner')
require('./common/cookie_banner2')
require('./common/clipboard-button')
require('./common/yandex-map')
require('./common/star-form')

require('./common/polipop')

require('polipop/src/sass/core.scss')
require('polipop/src/sass/default.scss')

// Блоки
require('./blocks/index')

// Страницы
require('./pages/index')

//  Компоненты
require('../components/youtube-video')

require('jquery.maskedinput/src/jquery.maskedinput')
import '@fortawesome/fontawesome-free/js/all'

require('slick-carousel')
require('slick-carousel/slick/slick.scss')
require('slick-carousel/slick/slick-theme.scss')
;(() => {
  document.body.classList.add('no-transition')
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => document.body.classList.remove('no-transition'), 200)
  })
})()
