const $page_menu = document.querySelector('.page-menu');
const $page_menu_hamburger = $page_menu.querySelector('[data-page-menu-hamburger]');
const $page_menu_mobile_menu = $page_menu.querySelector('[data-page-mobile-menu]')

const toggleHamburgerPageIcon = () => {
    requestAnimationFrame(() => {
        // document.body.classList.toggle('mobile-menu_active');
        $page_menu_hamburger.classList.toggle('hamburger_active');
    })
};

const togglePageMobileMenu = () => {
    toggleHamburgerPageIcon();
    requestAnimationFrame(() => (
        $page_menu_mobile_menu.classList.toggle('active')
    ))
};

$page_menu_hamburger.addEventListener('click', function (e) {
    e.preventDefault();
    togglePageMobileMenu(this);
});