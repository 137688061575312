const $inputGroups = document.querySelectorAll('.file-field.form-field');

$inputGroups.forEach($inputGroup => {
    const $input = $inputGroup.querySelector('input')
    const $text = $inputGroup.querySelector('.file-input__label')

    if($input.classList.contains('multiple_js')===false){
        $input.addEventListener('change', e => {
            if ($input.files.length) $text.innerText = $input.files[0].name;
        })
    }
})