document.addEventListener('DOMContentLoaded', ()=>{
    class ProductForm {
        constructor() {
            this.forms = document.querySelectorAll('[data-product-form]')
            this.fields = {'product': [], 'service': []}
            this.root_category_radio = {'product': [], 'service': []}
            this.catalog_select_data = []
            this.catalog_select_element = ''
            this.catalog_select_value = null
            this.current_product_type = 'product'
            this.current_product_category = null
            this.current_product_select_showed = []
        }

        show_all_product(){
            this.hide_all()
            this.fields.product.forEach((element)=>{
                this.change_display_element(element,'block')
            })

            this.root_category_radio.service.forEach((element)=>{
                this.change_display_element(element,'none')
            })
            this.root_category_radio.product.forEach((element)=>{
                this.change_display_element(element,'block')
            })

            this.current_product_type = 'product'
            this.rebuild_catalog_select('product',this.current_product_category)
        }

        show_all_service(){
            this.hide_all()
            this.fields.service.forEach((element)=>{
                this.change_display_element(element,'block')
            })
            this.root_category_radio.product.forEach((element)=>{
                this.change_display_element(element,'none')
            })
            this.root_category_radio.service.forEach((element)=>{
                this.change_display_element(element,'block')
            })
            this.current_product_type = 'service'
            this.rebuild_catalog_select('service',this.current_product_category)
        }

        hide_all(){
            this.fields.product.forEach((element)=>{
                this.change_display_element(element,'none')
            })
            this.fields.service.forEach((element)=>{
                this.change_display_element(element,'none')
            })
            this.root_category_radio.product.forEach((element)=>{
                this.change_display_element(element,'none')
            })
            this.root_category_radio.service.forEach((element)=>{
                this.change_display_element(element,'none')
            })
        }

        change_display_element(element,display){
            if (typeof(element) != 'undefined' && element != null) {
                element.style.display = display
            }
        }

        rebuild_catalog_select(type,category){

            this.current_product_select_showed = []

            this.catalog_select_element.querySelectorAll('option').forEach((element)=>{
                element.remove()
            })

            this.catalog_select_data.forEach((item,index)=>{

                if(index===0){
                    this.create_option_element(item)
                }

                if(type==='product'){
                    if(item.is_service==='0'){
                        if(category!==null){
                            if(item.parent === category){
                                this.create_option_element(item)
                            }
                        }else{
                            this.create_option_element(item)
                        }
                    }
                }
                if(type==='service'){
                    if(item.is_service==='1'){
                        if(category!==null){
                            if(item.parent === category){
                                this.create_option_element(item)
                            }
                        }else{
                            this.create_option_element(item)
                        }
                    }
                }
            })


            if(this.current_product_select_showed.includes(this.catalog_select_value)){
                this.catalog_select_element.value = this.catalog_select_value
            }else{
                this.catalog_select_element.value = ''
            }

        }

        create_option_element(item){
            const option = document.createElement("option")
            option.innerText = item.name
            option.value = item.value

            this.catalog_select_element.add(option, null)
            this.current_product_select_showed.push(item.value)
        }


        init() {
            if(this.forms.length===0){
                return false
            }

            this.forms.forEach((form)=>{

                const product_type_radio_elements = form.querySelectorAll('[data-product-type-radio]')
                product_type_radio_elements.forEach((product_type_radio)=>{
                    product_type_radio.addEventListener('change', (e)=>{
                        if(product_type_radio.value==='1'){
                            this.show_all_product()
                        }
                        if(product_type_radio.value==='2'){
                            this.show_all_service()
                        }
                    })
                })

                const current_radio_select_type = form.querySelector('[data-product-type-radio]:checked').value
                if(current_radio_select_type==='1'){
                    this.current_product_type = 'product'
                }
                if(current_radio_select_type==='2'){
                    this.current_product_type = 'service'
                }


                this.fields.product.push(form.querySelector('.form-field.sizes'))
                this.fields.product.push(form.querySelector('.form-field.colors'))
                this.fields.product.push(form.querySelector('.form-field.consignment_min'))
                this.fields.product.push(form.querySelector('.form-field.delivery_time'))
                this.fields.product.push(form.querySelector('.form-field.price_min'))
                this.fields.product.push(form.querySelector('.form-field.supply_condition'))

                this.fields.service.push(form.querySelector('.form-field.company_project'))

                this.root_category_radio.product = form.querySelectorAll('[data-catalog-radio-is-product]')
                this.root_category_radio.service = form.querySelectorAll('[data-catalog-radio-is-service]')


                this.catalog_select_element = form.querySelector('[data-catalog-select]')

                this.catalog_select_value = this.catalog_select_element.value


                this.catalog_select_element.querySelectorAll('option').forEach((element)=>{
                    this.catalog_select_data.push({
                        'name':element.innerText,
                        'value':element.value,
                        'parent':element.dataset.catalogSelectParent,
                        'is_service':element.dataset.catalogSelectIsService,
                    })
                })


                const root_selects = form.querySelectorAll('[data-root-category-id]')

                root_selects.forEach((element)=>{
                    element.addEventListener('change', (e)=>{
                        this.current_product_category = element.dataset.rootCategoryId
                        this.rebuild_catalog_select(this.current_product_type,element.dataset.rootCategoryId)
                    })
                })

                const current_product_category_checked = form.querySelector('[data-root-category-id]:checked')

                if(current_product_category_checked!==null){
                    this.current_product_category = current_product_category_checked.value
                }
            })



            if(this.current_product_type === 'product'){
                this.show_all_product()
            }
            if(this.current_product_type === 'service'){
                this.show_all_service()
            }


            return this;
        }
    }

    const product_form = new ProductForm().init()
})