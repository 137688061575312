require('./_index');
require('./_events-view');
require('./_events-list');
require('./_publishing-house');
require('./catalog/index');
require('./catalog/company');
// require('./contacts');
require('./cart');
require('./learning/index');
require('./news/index');

require('./user/profile/config_form');
require('./user/profile/multipleImages');
require('./user/profile/extra_menu');
require('./user/profile/product_form');

require('./megasale/_booth');
require('./megasale/_index');
require('./megasale/_program');