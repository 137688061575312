import expert from "../../blocks/megasale/expert";
import customer from "../../blocks/megasale/customer";
import reward from "../../blocks/megasale/reward";
import review from "../../blocks/megasale/review";
import history from "../../blocks/megasale/history";
import products from "../../blocks/megasale/products";

if (document.body.classList.contains('megasale-page')) {
    expert();
    customer();
    reward();
    review();
    history();
    products();
}
