/**
 *  Можно указать селектор к которому применится класс 'success' после успешной обработки формы
 *  По-умолчанию класс 'success' добавится непосредственно форме
 *
 *  data-success-selector=".success-holder"
 *
 *  <form action="{% url 'namespace:route' %}" method="post" data-ajax-form="ContactForm">
 *      ...
 *  </form>
 */

import {validatorValidateForm, validatorCleanErrors} from '../../components/forms/validation';
import LiveEvent from "../../components/live/live";
import Jax from "../../components/jax/jax";

new LiveEvent('submit', '[data-ajax-form]', function submitAjax(e) {
    e.preventDefault();
    const classes = this.dataset.ajaxForm.split(',');
    const successSelector = this.dataset.successSelector;
    const success = successSelector ? document.querySelector(successSelector) : this;
    const successTrigger = this.dataset.successTrigger;
    // const hasDataSendIndicator = this.hasAttribute('data-send-indicator');
    // let $sendIndicator;

    // if (hasDataSendIndicator) {
    //     $sendIndicator = document.querySelector('.b-form__indicator');
    //     $sendIndicator.classList.remove('hide');
    // }

    const button = this.querySelector('button');
    const form = this;
    const button_text = button.innerHTML;
    button.innerHTML = '<i class="fas fa-circle-notch fa-spin" style="color: white"></i>';
    button.disabled = true;
    form.classList.remove('has_errors','has_status_error');

    success.classList.remove('success');

    const jax = new Jax(this.getAttribute('action'), this.getAttribute('method'), true);

    jax.send(new FormData(this)).then((data, xhr) => {
        // if (hasDataSendIndicator) {
        //     $sendIndicator.classList.add('hide');
        // }

        if(jax.request.status!==200){
            form.classList.add('has_status_error');
            button.disabled = false;
            button.innerHTML = button_text;
            form.scrollIntoView({behavior: "smooth"});
            polipop.add({
                content: 'Обратитесь в техническую поддержку',
                title: 'Ошибка сервера!',
                type: 'error',
            });
        }

        button.innerHTML = button_text;
        button.disabled = false;

        let errorsList = {};
        if (data.errors) {
            errorsList = data.errors;
            form.scrollIntoView({behavior: "smooth"});
            form.classList.add('has_errors');
            polipop.add({
                content: 'Проверьте ошибки в форме, они выделены красным',
                title: 'Ошибка отправки!',
                type: 'error',
            });
        }
        Object.keys(classes).forEach((i) => {
            const cls = classes[i];
            if (errorsList[cls]) {
                validatorValidateForm(this, cls, errorsList[cls]);
            } else {
                validatorCleanErrors(this, cls);
            }
        });
        if (data.state === 'success') {
            polipop.add({
                content: 'Данные успешно отправлены',
                title: 'Отправлено!',
                type: 'success',
            });
            success.classList.add('success');
            if (successTrigger) {
                document.dispatchEvent(new CustomEvent('ajax-form-success', {'detail': {'form': this}}));
            }
            if (this.dataset.goal) {
                window.goal(this.dataset.goal);
            }
            if (data.redirect) {
                window.location.replace(data.redirect);
            }
            const clearTimeout = this.getAttribute('data-clear-timeout');
            if (clearTimeout) {
                setTimeout(() => {
                    this.reset();
                    document.dispatchEvent(new CustomEvent('success', {'detail': {'form': this}}));
                    success.classList.remove('success');
                }, clearTimeout);
            }
        }
    })
});

/**
 Пример action:

 public function contact()
 {
     if (!$this->request->getIsAjax() || !$this->request->getIsPost()) {
            $this->error(404);
        }
        $form = new RequestForm();
        $data = [
            'state' => 'success'
        ];
        if (!($form->fill($_POST) && $form->valid && $form->send())) {
            $data = [
                'state' => 'error',
                'errors' => [
                    $form->classNameShort() => $form->getErrors()
                ]
            ];
        }
        echo json_encode($data);
 }

 */
