export default function () {
    let block = document.querySelector(".js-circle-time");

    if(block !== null) {


        let timer = document.querySelector(".timer"),
            year = document.querySelector(".js-circle-year"),
            data = document.getElementsByClassName("js-years"),
            container = document.querySelector(".container-descriptions"),
            month = document.querySelector(".js-month"),
            times = document.getElementsByClassName("js-time-date"),
            arrDates = [];


        function updateBox (time) {

            let pxScroll = container.scrollTop;

            if ( time === 0 ) {
                let heigth = 30;
                for (let i = 0; data.length - ( data.length - 1 ) > i; i++) {
                    heigth += data[i].clientHeight + 30;
                }



                if ( pxScroll < heigth ) {
                    timer.style.transform = "rotate(calc(" + time + "*45deg))";
                    year.textContent = arrDates[time]["current"];
                    month.textContent = arrDates[time]["month"];
                    data[time].classList.add("is-active");


                    for (let i = 0; times.length > i; i++) {

                        times[i].textContent = arrDates[time]["other"][i];

                    }
                } else if (data[time].classList.contains("is-active")) {
                    timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                    data[time].classList.remove("is-active");
                }

            } else if (time === ( data.length - 1 )) {

                let heigth = 30;

                for (let i = 0; data.length  - 1 > i; i++) {
                    heigth += data[i].clientHeight + 30;
                }


                if (heigth < pxScroll) {

                    timer.style.transform = "rotate(calc(" + time + "*45deg))";
                    year.textContent = arrDates[time]["current"];
                    month.textContent = arrDates[time]["month"];
                    data[time].classList.add("is-active");


                    for (let i = 0; times.length > i; i++) {

                        times[i].textContent = arrDates[time]["other"][i];

                    }

                } else if (data[time].classList.contains("is-active")) {
                    timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                    data[time].classList.remove("is-active");
                }
            }else {

                let heigthMin = 30,
                    heigthMax = 30;

                for (let i = 0;  time > i; i++) {

                    heigthMin += data[i].clientHeight + 30;
                }

                for (let i = 0;  time + 1 > i; i++) {
                    heigthMax += data[i].clientHeight + 30;
                }


                if ((heigthMin <= pxScroll ) &&
                    (pxScroll < heigthMax  )) {


                    timer.style.transform = "rotate(calc(" + time + "*45deg))";
                    year.textContent = arrDates[time]["current"];
                    month.textContent = arrDates[time]["month"];
                    data[time].classList.add("is-active");


                    for (let i = 0; times.length > i; i++) {

                        times[i].textContent = arrDates[time]["other"][i];

                    }

                } else if (data[time].classList.contains("is-active")) {
                    timer.style.transform = "rotate(calc(" + time + "*45deg - 45deg))";
                    data[time].classList.remove("is-active");
                }

            }

        }


        function setDates(){

            let data_array = []

            for (let i = 0; data.length > i; i++) {
                let year_element = data[i].querySelector(".date__span-year")
                let month_element = data[i].querySelector(".date__span-month")

                data_array.push({
                    "current" : year_element.textContent,
                    "month" : month_element.textContent
                })
            }

            for(let i = 0; data_array.length > i; i++){
                let num1 = '',
                    num2 = '',
                    num3 = '',
                    num4 = ''

                if(data_array[i-1]!==undefined){
                    num1 = data_array[i-1].current
                }

                if(data_array[i-2]!==undefined){
                    num2 = data_array[i-2].current
                }

                if(data_array[i+1]!==undefined){
                    num3 = data_array[i+1].current
                }

                if(data_array[i+2]!==undefined){
                    num4 = data_array[i+2].current
                }

                data_array[i] = {
                    "current" : data_array[i].current,
                    "month" : data_array[i].month,
                    "other" : [num1, num2, num3, num4]
                }


            }


            arrDates = data_array


        }

        function init(){
            for (let i = 0; arrDates.length > i; i++ ) {
                container.addEventListener("scroll", () => {updateBox(i)});
            }
        }

        setDates()
        init()
    }

}