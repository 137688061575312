document.addEventListener('DOMContentLoaded', ()=> {
    const extra_menu_link = document.querySelectorAll('[data-extra-menu]')
    const need_open = document.querySelectorAll('[data-extra-menu-ul-open]')
    const all_ul = document.querySelectorAll('[data-extra-menu-ul]')

    need_open.forEach(element => {
        const id_to_open = element.dataset.extraMenuUlOpen
        const ul_menu = document.querySelector('[data-extra-menu-ul="'+id_to_open+'"]')
        all_ul.forEach(element => {
            element.style.display = 'none'
        })
        ul_menu.style.display = 'block'
    })

    extra_menu_link.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault()

            const id_menu = link.dataset.extraMenu
            const ul_menu = document.querySelector('[data-extra-menu-ul="'+id_menu+'"]')

            all_ul.forEach(element => {
                element.style.display = 'none'
            })

            ul_menu.style.display = 'block'
        })
    });



})