document.addEventListener('DOMContentLoaded', () => {
  const forms = document.querySelectorAll('form[data-star-form]')

  forms.forEach((form) => {
    form.addEventListener('submit', async (event) => {
      event.preventDefault()

      const formData = new FormData(form)
      const data = Object.fromEntries(formData.entries())
      const action = form.getAttribute('action')

      console.log('Отправка формы:', data)
      if (!data.rating) {
        alert('Выберите оценку')
        return
      }

      form.classList.add('loading')

      try {
        const response = await fetch(action, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
            id: form.dataset.starId,
            user: form.dataset.starUser,
            pid: form.dataset.starPid,
          }),
        })

        if (response.ok) {
          form.classList.add('done')
          console.log('Форма успешно отправлена')
        } else {
          console.error('Ошибка при отправке формы')
        }
      } catch (error) {
        console.error('Ошибка сети:', error)
      } finally {
        form.classList.remove('loading')
      }
    })
  })
})
