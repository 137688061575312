import Glide from "@glidejs/glide";
import gridGap from "../../common/gridGap";
import {bp} from '../../breakpoints';
import emit from '../../common/emit'

export default function () {


    const speakersSlider = document.querySelector('[data-expert-slider]');
    const backButton = document.querySelectorAll('[data-expert-slider-back]');
    const forwardButton = document.querySelectorAll('[data-expert-slider-forward]');
    const sliderSettings = {
        type: 'slider',
        perView: 1,
        gap: gridGap(),
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10,
        breakpoints: {
            [bp.sm.to]: {
                perView: 1
            },
            [bp.md.from]: {
                perView: 1
            },
            [bp.lg.to]: {
                perView: 1
            },
            [bp.xl.from]: {
                perView: 1
            }
        }
    };

    window.addEventListener('load', () => {
        const glide = new Glide(speakersSlider, sliderSettings);
        backButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('<');
            });
        });
        forwardButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('>')
            });
        })
        glide.on('run', () => (
            emit('updateLazyImages')
        ))
        glide.mount()
        emit('updateLazyImages')
    })
}