window.addEventListener('DOMContentLoaded', e => {
    let setCookie = function(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    let getCookie = function(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    let cookie_banner_visited = getCookie("cookie_banner_visited");

    if(cookie_banner_visited>0){
        setCookie('cookie_banner_visited',(cookie_banner_visited*1)+1,5);
    }else{
        setCookie('cookie_banner_visited',1,5);
    }

    document.querySelectorAll('[data-cookie-banner]').forEach(banner => {
        if(cookie_banner_visited<=2){
            banner.style.display = 'block'
        }
        const close = banner.querySelector('.icon-closer');
        close.addEventListener('click', () => {
            banner.style.display = 'none'
            setCookie('cookie_banner_visited',3,5);
        })
    });
});