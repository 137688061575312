import Glide from "@glidejs/glide";
import gridGap from "../../common/gridGap";
import {bp} from '../../breakpoints';
import emit from '../../common/emit'

export default function () {
    const rootButtons = document.querySelectorAll('[data-company-root-button]');
    const allButton = document.querySelector('[data-company-all-button]');
    const companies_block = document.querySelector('.b-company');


    function show_root(root_id){
        hide_all();
        const items = document.querySelectorAll('.company-root-'+root_id);
        items.forEach(item => {
            item.classList.add('show')
        });
    }

    function show_all(){
        hide_all();
        const roots = companies_block.querySelectorAll('.b-company__item');
        roots.forEach(element => {
            element.classList.add('show')
        });
    }

    function hide_all(){
        const roots = companies_block.querySelectorAll('.b-company__item');
        roots.forEach(element => {
            element.classList.remove('show')
        });
    }


    document.addEventListener('DOMContentLoaded', () => {

        rootButtons.forEach(element => {
            const category_id = element.dataset.companyRootButton

            element.addEventListener('click', event => {
                show_root(category_id);

                rootButtons.forEach(root_element => {
                    root_element.classList.remove('active')
                })
                allButton.classList.remove('active')

                element.classList.add('active')
            });
        });


        allButton.addEventListener('click', e => {
            show_all();
            allButton.classList.add('active')
            rootButtons.forEach(root_element => {
                root_element.classList.remove('active')
            })
        });

    })
}