document.addEventListener('DOMContentLoaded', function () {
  // Находим все элементы с атрибутом data-clipboard
  const clipboardElements = document.querySelectorAll('[data-clipboard]')

  // Функция для копирования текста в буфер обмена
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        // console.log('Текст скопирован в буфер обмена')
      })
      .catch(function (err) {
        // console.error('Ошибка при копировании текста: ', err)
      })
  }

  // Добавляем обработчик событий для каждого элемента
  clipboardElements.forEach(function (element) {
    element.addEventListener('click', function () {
      const textToCopy = element.getAttribute('data-clipboard')
      copyToClipboard(textToCopy)

      const originalInnerHTML = element.innerHTML
      element.innerHTML = '<i class="fas fa-check"></i>'

      setTimeout(function () {
        element.innerHTML = originalInnerHTML
      }, 2000)
    })
  })
})
