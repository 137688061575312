import Glide from '@glidejs/glide'
import gridGap from "../../common/gridGap";
import emit from '../../common/emit'
import {PlusMinus} from "../../components/plus-minus";
import initOtherProductsSlider from '../../blocks/index/goods'
import Favorites from "../../components/Favorites";
import projects from "./_projects"

const $page = document.querySelector('.catalog-view-page');

const initPriceInput = () => {
    const $toCartButtons = $page.querySelectorAll('[data-to-cart]');
    const $plusMinus = $page.querySelector('.form-field.plus-minus');
    const plusMinusInstance = new PlusMinus($plusMinus, {
        min: $plusMinus.getAttribute('data-min-count'),
        step: 1,
    });

    const $price = $page.querySelector('[data-calc-price="price"]');
    const $priceForOne = $page.querySelector('[data-calc-price="price-for-one"]');
    const $priceNoSale = $page.querySelector('[data-calc-price="price-no-sale"]');
    const $priceForOneNoSale = $page.querySelector('[data-calc-price="price-for-one-no-sale"]');


    plusMinusInstance.$field.addEventListener('value-changed', e => {

        $toCartButtons.forEach($toCartButton => {
            $toCartButton.setAttribute('data-quantity', plusMinusInstance.value);
        })

        if ($priceForOne) {
            const price = Number($priceForOne.innerText);
            const value = plusMinusInstance.value * price;
            $price.innerText = Number(value.toFixed(1)).toLocaleString(); // приведение к виду 12 000 100
            if($priceForOneNoSale){
                const price_no_sale = Number($priceForOneNoSale.innerText);
                const value_no_sale = plusMinusInstance.value * price_no_sale;
                $priceNoSale.innerText = Number(value_no_sale.toFixed(1)).toLocaleString() + '₽'; // приведение к виду 12 000 100
            }
        }
    })

};
const initImageSlider = () => {
    const $imageSlider = $page.querySelector('[data-product-images-slider]');
    const $imageNavSlider = $page.querySelector('[data-product-images-slider-nav]');

    const sliderSettings = {
        type: 'slider',
        perView: 1,
        gap: gridGap(),
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10
    };
    const navSliderSettings = {
        type: 'slider',
        perView: 3,
        gap: gridGap() / 2,
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10,
        focusAt: 0
    };

    document.addEventListener('DOMContentLoaded', () => {
        try {
            const glide = new Glide($imageSlider, sliderSettings);
            const navGlide = new Glide($imageNavSlider, navSliderSettings);
            glide.on('run', () => {
                emit('updateLazyImages');
                navGlide.go(`=${glide._i}`);
            });
            glide.mount();
            navGlide.on('mount.after', () => {
                const $glide = navGlide.selector;
                const $slides = $glide.querySelectorAll('.glide__slide');
                $slides.forEach(($slide, index) => {
                    $slide.addEventListener('click', e => {
                        navGlide.go(`=${index}`)
                    })
                })
            });

            navGlide.on('run', () => {
                emit('updateLazyImages');
                glide.go(`=${navGlide._i}`)
            });
            navGlide.mount();
        } catch (e) {
            console.error(e)
        }
    })
};

const lazyOtherProducts = () => {
    (() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio === 1) {
                    setData();
                }
            });
        }, {
            threshold: 1
        });
        observer.observe($page.querySelector('[data-other-products-container]'));
    })();

    const setData = async () => {
        const $otherProductsContainer = $page.querySelector('[data-other-products-container]');
        const url = $otherProductsContainer.getAttribute('data-url');

        try {
            const response = await fetch(url);
            const json = await response.json();
            if (json.error === true) return console.error('error');
            if (json.html) {
                $otherProductsContainer.outerHTML = json.html;
                initOtherProductsSlider();
                emit(['load', 'updateLazyImages']);
                new Favorites({routes:{},elements:{closest:'.section_name_other-products'}});
            }
        } catch (e) {
            console.log(e.message)
        }
    }
};

if ($page) {
    projects();
    initPriceInput();
    initImageSlider();
    lazyOtherProducts();

}
